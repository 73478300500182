<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <img 
          src="https://ik.imagekit.io/aryraditya/ceremonyku/corner_2x_sZXnEWtgp.png?tr=w-500"
          style="position: absolute; top: 0; right: 0; width: 75vw; max-width: 400px; transform: translateY(-20%) translateX(20%); opacity: .5"
        />
        <img 
          src="https://ik.imagekit.io/aryraditya/ceremonyku/floral-gold-1_2x.png?updatedAt=1633157420035?tr=w-500"
          style="position: absolute; bottom: 0; left: 0; width: 75vw; max-width: 400px; transform: translateY(30%) translateX(-20%) rotate(30deg); opacity: .7"
        />
        <div class="text-center w-100">
          <!-- <div class="title">Undangan {{ title }}</div>
          <span>from</span> -->
          <div class="mb-2 name"  data-aos="fade-down" data-aos-delay="200">
            {{ wedding.man.surname }}
            <div class="d-block" style="margin-top: -.75rem; margin-bottom: -.75rem">&</div>
            {{ wedding.woman.surname }}
          </div>

          <div class="guest-box mb-3 d-block mx-auto" data-aos="fade-up" data-aos-delay="600">
            <div class="guest-box__title">Kepada {{ $root.guest.title || 'Bpk/Ibu/Sdr/i' }}.</div>
            <div class="guest-box__name" style="min-height: 31px; min-height: 3vh">
              <span>{{ $root.guest.name }}</span>
            </div>

            <div class="guest-box__note">Tanpa mengurangi rasa hormat, kami mengundang anda untuk hadir pada acara pernikahan kami.</div>
          </div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio ref="audio" :file="music.file" :loop="music.loop" :autoplay="music.autoplay"/>
    <AGWHero overlay :background="backgrounds" ref="hero" parallax target="#intro"/>

    <div class="bg-white agw-content" :style="{ 'margin-top' : `${$root.hero.height}px`, overflow: 'hidden' }">
      <AGWIntro id="intro"/>

      <div class="pad-y-60">
        <AGWPawiwahan id="pawiwahan"/>
        <AGWMepandes id="mepandes" class="mar-top-60" :mepandes="mepandes"/>
      </div>

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d986.3099690647506!2d115.29556682922244!3d-8.572918999614865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1369a546a55211b4!2zOMKwMzQnMjIuNSJTIDExNcKwMTcnNDYuMCJF!5e0!3m2!1sen!2sid!4v1647177691825!5m2!1sen!2sid"
        directionUrl="https://goo.gl/maps/s2YuN7RCQwmBqo9B9"
        :location="location"
        :time="time"
        id="map"
      />
      <a
        href="https://goo.gl/maps/s2YuN7RCQwmBqo9B9"
        class="items-center justify-content-center"
        style="padding: 1rem 2rem; text-transform: uppercase; text-decoration: none; letter-spacing: .25rem; background: #C29513; color: #fff; width: 100%; display: flex; text-align: center"
      >
        <i class="material-icons mr-2">directions</i>
        Buka Peta
      </a>
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <div :style="{ 'height' : `${$root.dividerHeight}px` }"></div>

    <Footer ref="footer" />

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height"/>
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 = 'https://images.unsplash.com/photo-1588814096146-e7c56156f9f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80'
// const ImageHero2 = 'https://ik.imagekit.io/crk/adek-suja/IMG_4802.JPG?updatedAt=1645883066327&tr=w-533,h-797,fo-custom,cm-extract'
// const ImageHero3 = 'https://ik.imagekit.io/crk/adek-suja/IMG_4793.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1645882209746&tr=w-1024'

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Himbauan from '@/components/Himbauan.vue';
import Attendance from "@/components/Attendance";
import Footer from "@/components/Footer/Ceremonyku.vue";

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
    Footer,
  },
  data() {
    return {
      title: 'Pawiwahan',
      backgrounds: [ImageHero1],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      wedding: {
        man: {
          name: 'I Putu Wahyu Wiguna',
          surname: 'Wahyu',
          parents: 'I Made Gus & Ni Putu Wiratniti',
          description: 'Putra pertama',
          address: 'Br. Blangsinga, Ds. Saba, Blahbatuh, Gianyar',
          photo: '',
        },
        woman: {
          name: 'Gusti Ayu Cahya Lestari',
          surname: 'Cahya Lestari',
          parents: 'Gusti Ngurah Dedy & Jero Tunjung',
          address: 'Br. Blangsinga, Ds. Saba, Blahbatuh, Gianyar',
          description: 'Putri pertama',
          photo: '',
        },
      },
      location: "Br. Blangsinga, Ds. Saba, Blahbatuh, Gianyar",
      time: {
        start: this.$moment("2022-03-23 15:00"),
        end: "Selesai"
      },
      mepandes: [
      ],
      music: {
        file: Audio,
        autoplay: true,
        loop: true
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    }
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // if(navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("controllerchange", () => {
    //   if (this.sw.refreshing) return;
    //   // this.sw.refreshing = true;
    //   // window.location.reload();
    // });
    // }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
      this.sw.refreshing = true;
      window.location.reload();
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  }
};
</script>
