<template>
  <section class="bg-blue-light pad-y-60 text-center">
    <div class="container">
      <div
        data-aos="fade-down"
        class="heading text-center with-border aos-init aos-animate mb-5 mt-5"
      >
        <h2 class="title text-uppercase text-teal-dark">Himbauan</h2>
      </div>

      <p class="pt-3">
        Demi kenyamanan bersama, Kami menghimbau kepada Bapak/Ibu/Saudara/i yang
        berkesempatan dan berkenan hadir pada upacara pawiwahan putra-putri Kami, agar
        senantiasa mengikuti arahan Pemerintah terkait protokol kesehatan di
        tengah Pandemi Covid-19 ini.
      </p>

      <div class="d-flex justify-content-center py-5">
        <div class="row justify-content-center" style="max-width: 800px">
          <div
            class="col-4 col-md-4 d-flex justify-content-center"
            v-for="item in instructions"
            :key="item.title"
          >
            <div class="mb-4 w-100">
              <img :src="item.icon" style="max-width: 125px; width: 70%" />
              <div class="mt-2" style="font-weight: 500; font-size: .9rem">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <p>Atas perhatian dan kesadaran Bapak/Ibu/Saudara/i, Kami ucapkan Terimakasih.</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      instructions: [
        {
          icon:
            "https://ik.imagekit.io/aryraditya/ceremonyku/handwash_2x_HtXxA8kZAA7s.png",
          title: "Mencuci Tangan",
        },
        {
          icon:
            "https://ik.imagekit.io/aryraditya/ceremonyku/mask_2x_dSvnS69kCL0X.png",
          title: "Menggunakan Masker",
        },
        {
          icon:
            "https://ik.imagekit.io/aryraditya/ceremonyku/social-distancing_2x_FjBfbCY6BpFA.png",
          title: "Menjaga Jarak",
        },
        {
          icon:
            "https://ik.imagekit.io/aryraditya/ceremonyku/handsanitizer_2x_BhrGd2kY9neE.png",
          title: "Menggunakan Hand Sanitizer",
        },
        {
          icon:
            "https://ik.imagekit.io/aryraditya/ceremonyku/shake_2x_6X083E75u4c.png",
          title: "Tidak Berjabat Tangan",
        },
      ],
    };
  },
};
</script>